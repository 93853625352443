import React, { useEffect, useState, useCallback, useRef } from "react";
import { fetchStatus } from "../../api/user";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Waiting = () => {
  const [text, setText] = useState("Checking with the cafe...");
  const order = useSelector((state) => state.user.order);
  const navigate = useNavigate();
  const intervalRef = useRef(null);
  const reasons = useRef([
    "Cafe is busy, trying again!",
    "Sabka time aayega!",
    "Araam se baitho, ham karte hain prabandh!",
    "Kuch der aur ruk jao!",
    "Kuch der aur intezar karo!",
  ]);

  const pollStatus = useCallback(() => {
    fetchStatus()
      .then((res) => {
        if (res.data === "PREPARING") {
          window.location.href = `/order/bill/${order._id}`;
          clearInterval(intervalRef.current);
        } else if (res.data === "REJECTED") {
          window.location.href = "/order/rejected";
          clearInterval(intervalRef.current);
        }
      })
      .catch((err) => {
        clearInterval(intervalRef.current);
        if (err.response?.data?.message === "Cafe is closed") {
          navigate("/cafe/closed");
        }
      });
  }, [order._id, navigate]);

  useEffect(() => {
    pollStatus();
    intervalRef.current = setInterval(pollStatus, 5000);

    let index = 0;
    const textInterval = setInterval(() => {
      if (index < reasons.current.length) {
        setText(reasons.current[index]);
      } else {
        setText("This is humiliating, can you please wait a bit more?");
      }
      index++;
    }, 9000);

    return () => {
      clearInterval(intervalRef.current);
      clearInterval(textInterval);
    };
  }, [pollStatus]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <p>{text}</p>
      <img src="../Countdown.gif" alt="Loading" className="h-60" />
      Your order is waiting to be accepted by the cafe.
    </div>
  );
};

export default React.memo(Waiting);