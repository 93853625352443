import React from 'react';
import { Link } from 'react-router-dom';

const CafeCard = ({ cafe }) => {
  const imageUrl = cafe.image ? `data:image/webp;base64,${cafe.image}` : null;

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      {imageUrl && (
        <div className="relative h-48">
          <img 
            src={imageUrl}
            alt={cafe.name}
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 right-0 bg-black bg-opacity-50 text-white p-2 rounded-tl-lg">
            <span className="text-sm">{cafe.distance || '2km away'}</span>
          </div>
        </div>
      )}
      <div className="p-4">
        <h3 className="text-xl font-semibold text-gray-800">{cafe.name}</h3>
        <div className="flex items-center mt-2">
          <span className="text-yellow-400">{'★'.repeat(Math.floor(cafe.rating || 4))}</span>
          <span className="text-gray-300">{'★'.repeat(5 - Math.floor(cafe.rating || 4))}</span>
          <span className="ml-2 text-gray-600">{Math.floor(cafe.rating || 4.0)}</span>
        </div>
        <p className="text-sm text-gray-600 mt-2">{cafe.address}</p>
      </div>
    </div>
  );
};

export default CafeCard;