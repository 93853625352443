import React, { useState, useEffect } from 'react';
import { fetchOrder, updateStatus } from '../api/cafe';
import { updateOrderStatusInState } from '../redux/slices/admin';
import { useDispatch } from 'react-redux';
import "../pages/cafe_admin/style.css";
import UniversalInput from './Universalnput';

const OrdersItem = ({ order, showItems = true, showDetails }) => {
  const [orderObj, setOrderObj] = useState({});

  function fetchOrderFromDB() {
    fetchOrder(order._id).then((res) => {
      setOrderObj(res.data);
    });
  }

  useEffect(() => {
    fetchOrderFromDB();
  }, [order._id]);

  return (
    <div
      key={order._id}
      className="w-full bg-white shadow-md border border-[#FD7347] p-4 my-4 rounded-lg"
    >
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          {orderObj.ordertype === "DINEIN" && <span className='bg-blue-600 text-white py-1 px-4 shadow-md rounded-full text-xs mr-2'>DINE IN</span>}
          {orderObj.ordertype === "TAKEAWAY" && <span className='bg-yellow-400 text-white py-1 px-4 shadow-md rounded-full text-xs mr-2'>TAKEAWAY</span>}
          <h2 className="text-lg font-semibold">
            Table: <span>{order.tablenumber || 'N/A'}</span>
          </h2>
        </div>
      </div>
      {showItems && <OrderDetails order={orderObj} />}
    </div>
  );
};

const OrderDetails = ({ order }) => {
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState([]);
  const [paymentType, setPaymentType] = useState('');

  function checkIfReadyToProceed() {
    let cont = true;
    for (let item of order.items) {
      if (!localStorage.getItem(`OG:${order._id}:${item._id}`)) {
        cont = false;
        return;
      }
    }
    cont && markOrderedServed();
  }

  useEffect(() => {
    if (order.status === "PREPARING") {
      checkIfReadyToProceed();
    }
  }, [order]);

  function markOrderedServed() {
    updateStatus("SERVED", order._id)
      .then(() => {
        dispatch(updateOrderStatusInState({
          date: (new Date(order.createdat).toDateString().split(" ").slice(1).join(" ")).toString(),
          status: "SERVED",
          prev: "PREPARING",
          _id: order._id
        }));
      })
      .catch(error => console.error("Error marking order as served:", error));
  }

  function markOrderedCompleted(paymentType) {
    setPaymentType(paymentType);
    updateStatus("COMPLETED", order._id)
      .then(() => {
        dispatch(updateOrderStatusInState({
          date: (new Date(order.createdat).toDateString().split(" ").slice(1).join(" ")).toString(),
          status: "COMPLETED",
          prev: "SERVED",
          _id: order._id
        }));
      })
      .catch(error => console.error("Error marking order as completed:", error));
  }

  function acceptOrder() {
    updateStatus("PREPARING", order._id)
      .then(() => {
        dispatch(updateOrderStatusInState({
          date: (new Date(order.createdat).toDateString().split(" ").slice(1).join(" ")).toString(),
          status: "PREPARING",
          prev: "ORDERED",
          _id: order._id
        }));
      })
      .catch(error => console.error("Error accepting order:", error));
  }

  function rejectOrder() {
    updateStatus("REJECTED", order._id)
      .then(() => {
        dispatch(updateOrderStatusInState({
          date: (new Date(order.createdat).toDateString().split(" ").slice(1).join(" ")).toString(),
          status: "REJECTED",
          prev: "ORDERED",
          _id: order._id
        }));
      })
      .catch(error => console.error("Error rejecting order:", error));
  }

  const renderOrderItems = () => (
    <>
      {order.items.map((item, index) => (
        <div key={index} className="flex w-full justify-between p-3 border-b last:border-b-0 border-dashed">
          <div className="flex flex-col items-start">
            <p className="font-medium">{item.name}</p>
            {item.variant && <p className="text-sm text-[#FD7347]">{item.variant}</p>}
          </div>
          <div className="flex items-center">
            <p className="text-sm mr-2">{item.quantity}<span className="text-[#FD7347] text-xs font-medium">x</span></p>
            <p className="text-sm font-medium">₹{item.price}</p>
          </div>
        </div>
      ))}
      {order.note && (
        <div className="bg-gray-100 rounded-lg text-sm text-gray-700 px-4 py-2 mt-4">
          <p><span className="font-medium">Note:</span> {order.note}</p>
        </div>
      )}
    </>
  );

  if (order.status === "ORDERED") {
    return (
      <div className="flex flex-col">
        {renderOrderItems()}
        <div className="flex w-full mt-4 justify-between ">
          <button onClick={acceptOrder} className=" mr-1 w-32 p-2 justify-center text-sm text-white flex items-center rounded font-semibold bg-[#FD7347]">Accept</button>
          <button onClick={rejectOrder} className="bg-white ml-1 w-32 p-2 justify-center text-sm text-[#FD7347] border-[#FD7347] border-2 opacity-70 flex items-center rounded">Reject</button>
        </div>
      </div>
    );
  } else if (order.status === "PREPARING") {
    return (
      <div className="flex flex-col">
        {order.items.map((item, index) => (
          <div key={index} className="flex w-full items-center py-2 border-b last:border-b-0 ">
          <UniversalInput 
           type="checkbox"
              id={`${order._id}:${item._id}`}
              checked={localStorage.getItem(`OG:${order._id}:${item._id}`)}
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem(`OG:${order._id}:${item._id}`, true);
                  setDisabled([...disabled, `${order._id}:${item._id}`]);
                  checkIfReadyToProceed();
                }
              }}
              // disabled={disabled.includes(`${order._id}:${item._id}`) || localStorage.getItem(`OG:${order._id}:${item._id}`)}
              className="mr-3 custom-checkbox"
            
          />
           
            <label htmlFor={`${order._id}:${item._id}`} className="flex-grow">
              {item.name} {item.variant && `(${item.variant})`}
            </label>
            <span className="text-sm">x{item.quantity}</span>
          </div>
        ))}
        {order.note && (
          <div className="bg-gray-100 rounded-lg text-sm text-gray-700 px-4 py-2 mt-4">
            <p><span className="font-medium">Note:</span> {order.note}</p>
          </div>
        )}
      </div>
    );
  } else if (order.status === "SERVED") {
    let total = 0;
    order.items.forEach(item => {
      total += item.price * item.quantity;
    });
    return (
      <div className="flex flex-col">
        {renderOrderItems()}
      </div>
    );
  } else {
    return (
      <p className="py-2 text-center text-gray-600">Loading Order Details...</p>
    );
  }
};

export default OrdersItem;