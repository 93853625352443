import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Calendar, ChevronLeft, ChevronRight, Users } from 'lucide-react';

const UserAnalyticsDashboard = () => {
  const [analytics, setAnalytics] = useState({
    summary: {
      total_unique_visitors: 0,
      total_visits: 0,
      total_registered_users: 0
    },
    users: [],
    pagination: {
      total: 0,
      page: 1,
      limit: 10,
      pages: 0
    }
  });
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState(() => new Date().toISOString().split('T')[0]);
  const [sortBy, setSortBy] = useState('createdat');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchAnalytics();
  }, [startDate, endDate, sortBy, sortOrder, currentPage]);

  const fetchAnalytics = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/superadmin/user-analytics?` +
        new URLSearchParams({
          start_date: startDate,
          end_date: endDate,
          sort_by: sortBy,
          sort_order: sortOrder,
          page: currentPage,
          limit: 10
        }),
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      if (data.success) {
        setAnalytics(data.data);
      } else {
        console.error('API returned error:', data.message);
      }
    } catch (error) {
      console.error('Error fetching analytics:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderPagination = () => (
    <div className="flex items-center justify-between mt-4">
      <button 
        onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
        disabled={currentPage === 1}
        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
      >
        <ChevronLeft className="w-4 h-4 mr-2" />
        Previous
      </button>
      <span>
        Page {currentPage} of {analytics.pagination.pages}
      </span>
      <button 
        onClick={() => setCurrentPage(p => Math.min(analytics.pagination.pages, p + 1))}
        disabled={currentPage === analytics.pagination.pages}
        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
      >
        Next
        <ChevronRight className="w-4 h-4 ml-2" />
      </button>
    </div>
  );

  const renderUserTable = () => (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Phone</th>
            <th className="px-4 py-2">Total Visits</th>
            <th className="px-4 py-2">QR Scans</th>
            <th className="px-4 py-2">Cafes Visited</th>
            <th className="px-4 py-2">Total Orders</th>
            <th className="px-4 py-2">Total Spent</th>
            <th className="px-4 py-2">Created At</th>
          </tr>
        </thead>
        <tbody>
          {analytics.users.map((user) => (
            <tr key={user.phone} className="hover:bg-gray-50">
              <td className="border px-4 py-2">{user.name}</td>
              <td className="border px-4 py-2">{user.phone}</td>
              <td className="border px-4 py-2">{user.analytics.total_visits}</td>
              <td className="border px-4 py-2">{user.analytics.qr_scans}</td>
              <td className="border px-4 py-2">{user.cafes_visited}</td>
              <td className="border px-4 py-2">{user.total_orders}</td>
              <td className="border px-4 py-2">₹{user.total_spent || 0}</td>
              <td className="border px-4 py-2">
                {new Date(user.createdat).toLocaleDateString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderUserDetails = (user) => (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <h3 className="text-lg font-semibold mb-2">{user.name}'s Activity</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h4 className="font-medium mb-2">Cafe Visits</h4>
          <div className="space-y-2">
            {Object.entries(user.analytics.cafes_data).map(([cafeId, data]) => (
              <div key={cafeId} className="flex justify-between">
                <span>{data.name}</span>
                <span>{data.visits} visits</span>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h4 className="font-medium mb-2">Page Visits</h4>
          <div className="space-y-2">
            {Object.entries(user.analytics.page_visits).map(([page, visits]) => (
              <div key={page} className="flex justify-between">
                <span>{page}</span>
                <span>{visits} visits</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderSummaryMetrics = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-600">Unique Visitors</h3>
        <p className="text-3xl font-bold mt-2">{analytics.summary.total_unique_visitors}</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-600">Total Visits</h3>
        <p className="text-3xl font-bold mt-2">{analytics.summary.total_visits}</p>
      </div>
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-gray-600">Registered Users</h3>
        <p className="text-3xl font-bold mt-2">{analytics.summary.total_registered_users}</p>
      </div>
    </div>
  );

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">User Analytics</h2>
        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <Calendar className="w-5 h-5 mr-2" />
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border rounded px-2 py-1"
            />
            <span className="mx-2">to</span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border rounded px-2 py-1"
            />
          </div>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="border rounded px-2 py-1"
          >
            <option value="createdat">Created Date</option>
            <option value="total_visits">Total Visits</option>
            <option value="total_orders">Total Orders</option>
            <option value="total_spent">Total Spent</option>
          </select>
          <select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            className="border rounded px-2 py-1"
          >
            <option value="DESC">Descending</option>
            <option value="ASC">Ascending</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <>
          {renderSummaryMetrics()}
          {renderUserTable()}
          {renderPagination()}
          <div className="mt-8">
            {analytics.users.map((user) => renderUserDetails(user))}
          </div>
        </>
      )}
    </div>
  );
};

export default UserAnalyticsDashboard;