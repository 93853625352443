import React, { useState, useEffect } from 'react';
import { BellOutlined, CloseOutlined } from '@ant-design/icons';

const NotificationBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    checkNotificationPermission();
  }, []);

  const checkNotificationPermission = () => {
    if ('Notification' in window) {
      if (Notification.permission === 'denied') {
        setShowBanner(true);
      }
    }
  };

  const handleEnableNotifications = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          setShowBanner(false);
          // You can add your notification subscription logic here
        } else {
          // Guide user to browser settings
          alert('Please enable notifications in your browser settings to receive updates.');
        }
      });
    }
  };
  

  const handleClose = () => {
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 flex items-center justify-between">
      <div className="flex items-center">
        <BellOutlined className="text-xl mr-2" />
        <div>
          <p className="font-bold">Enable Notifications</p>
          <p className="text-sm">Stay updated with our latest offers and menu items.</p>
        </div>
      </div>
      <div className="flex items-center">
        <button 
          onClick={handleEnableNotifications}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 flex items-center"
        >
          <BellOutlined className="mr-1" />
          Enable
        </button>
        <CloseOutlined 
          onClick={handleClose}
          className="text-xl cursor-pointer hover:text-blue-800"
        />
      </div>
    </div>
  );
};

export default NotificationBanner;