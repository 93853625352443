import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { createSession, getCafe } from "../../api/user";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setCafe } from "../../redux/slices/user";
import { getCafeIdByUsername } from "../../api/cafe";
import PropTypes from 'prop-types';

const LoadingSpinner = () => (
  <div className="w-full flex items-center justify-center h-screen">
    <img src="../loading.gif" alt="Loading" />
  </div>
);

const Init = () => {
  const dispatch = useDispatch();
  const { cafe } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const table = searchParams.get("table");
  const [loading, setLoading] = useState(true);
  const [cafeIsOpen, setCafeIsOpen] = useState(false);
  const [isUsername, setIsUsername] = useState(false);

  const fetchCafe = useCallback(async (cafeId) => {
    try {
      const res = await getCafe(cafeId);
      dispatch(setCafe({ ...res.data, table }));
      sessionStorage.setItem("OG_cafe", JSON.stringify({ ...res.data, table }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cafe:", error);
      setLoading(false);
    }
  }, [dispatch, table]);

  const loadItems = useCallback(async (cafeId) => {
    try {
      const res = await createSession(cafeId, table);
      await fetchCafe(cafeId);
      if (res.success) {
        setCafeIsOpen(true);
      } else {
        navigate('/cafe/closed');
      }
    } catch (error) {
      console.error("Error creating session:", error);
      navigate('/cafe/closed');
    }
  }, [fetchCafe, navigate, table]);

  useEffect(() => {
    const initializeSession = async () => {
      let cafeId = cafe;

      if (!/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(cafe)) {
        try {
          const response = await getCafeIdByUsername(cafe);
          if (response.success) {
            cafeId = response.data.cafeId;
            setIsUsername(true);
            sessionStorage.setItem("OG_isUsername", "true");
          } else {
            navigate('/404');
            return;
          }
        } catch (error) {
          console.error("Error fetching cafe ID by username:", error);
          navigate('/404');
          return;
        }
      }

      await loadItems(cafeId);
    };

    initializeSession();
  }, [cafe, loadItems, navigate]);

  useEffect(() => {
    if (cafeIsOpen && !loading) {
      const name = localStorage.getItem("OG_name");
      const phone = localStorage.getItem("OG_phone");
      if (name && phone) {
        navigate(isUsername ? `/cafe/profile` : `/cafe/menu`);
      } else {
        navigate(`/cafe/register`);
      }
    }
  }, [cafeIsOpen, loading, isUsername, navigate]);

  return loading ? <LoadingSpinner /> : null;
};

LoadingSpinner.propTypes = {
  message: PropTypes.string,
};

export default React.memo(Init);