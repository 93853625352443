import React, { useState, useEffect } from 'react';

const NotificationPopup = ({ onClose }) => {
    const [timeLeft, setTimeLeft] = useState(10);
    const [isVisible, setIsVisible] = useState(true);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (!isVisible) return;

        const timer = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    handleClose();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [isVisible]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsVisible(false);
            onClose();
        }, 300);
    };

    if (!isVisible) return null;

    return (
        <>
            {/* Dark overlay */}
            <div
                className={`fixed inset-0 z-40 bg-black transition-opacity duration-300 ${isClosing ? 'opacity-0' : 'opacity-70'
                    }`}
                onClick={handleClose}
            />

            {/* Popup content */}
            <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
                <div
                    className={`w-[70vw] bg-white rounded-xl shadow-lg overflow-hidden
            transition-all duration-300 transform
            ${isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}
                >
                    <div className="relative p-6">
                        {/* Close button with countdown */}
                        <button
                            onClick={handleClose}
                            className="absolute top-4 right-4 w-6 h-6"
                        >
                            <div className="absolute inset-0">
                                <svg className="w-6 h-6">
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeDasharray={`${(1 - timeLeft / 10) * 63} 63`}
                                        className="transform -rotate-90 transition-all duration-1000"
                                    />
                                </svg>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-gray-500 hover:text-gray-700 transition-colors"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>

                        <div className="flex flex-col md:flex-row items-center gap-6">
                            <div className="w-40 h-40 relative">
                                <img
                                    src="/allow_notifications.png"
                                    alt="Sure, I will allow notifications"
                                    className="w-full h-full object-contain"
                                />
                            </div>

                            <div className="flex-1 text-center md:text-left">
                                <h2 className="text-2xl font-bold mb-4 text-[#9277FF]">
                                    Welcome Foodie! 🎉
                                </h2>
                                <div className="space-y-4">
                                    <p className="text-lg">
                                        Please enable notifications to get{' '}
                                        <span className="font-semibold text-[#9277FF]">exclusive offers nearby</span>.
                                    </p>
                                    <p className="text-gray-600">
                                        New cafés are joining soon—more deals coming your way!
                                    </p>
                                </div>
                                <button
                                    onClick={handleClose}
                                    className="mt-6 bg-[#9277FF] text-white px-8 py-3 rounded-lg font-semibold hover:bg-[#7b5ff0] transition-colors"
                                    style={{ boxShadow: "2px 3px 0px 0px #000000" }}
                                >
                                    Sure, I will allow notifications                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotificationPopup;