import React, { useEffect, useState, useCallback } from "react";
import Header from "../../components/Header";
import BottomMenu from "../../components/BottomMenu";
import { fetchOrders, updateStatus, sendAlert } from "../../api/cafe";
import OrdersItem from "../../components/OrdersItem";
import { Helmet } from "react-helmet";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { setOrders, updateOrderStatusInState } from "../../redux/slices/admin";
import { Link } from "react-router-dom";
import Universalinput from "../../components/Universalnput"

const Orders = () => {
  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const [dates, setDates] = useState(new Date());
  const [currentData, setCurrentData] = useState({});
  const [showScreen, setShowScreen] = useState("ORDERED");
  const [pop, setPop] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  const ordersFromState = useSelector((state) => state.admin.orders);

  const [orderCounts, setOrderCounts] = useState({
    ORDERED: 0,
    PREPARING: 0,
    SERVED: 0,
  });

  const getOrders = useCallback(() => {
    fetchOrders()
      .then((res) => {
        setDate(Object.keys(res.data)[0]);
        setDates(Object.keys(res.data));
        setCurrentData(res.data);
        dispatch(setOrders(res.data));

        const counts = {
          ORDERED: res.data[Object.keys(res.data)[0]]?.ORDERED?.length || 0,
          PREPARING: res.data[Object.keys(res.data)[0]]?.PREPARING?.length || 0,
          SERVED: res.data[Object.keys(res.data)[0]]?.SERVED?.length || 0,
        };
        setOrderCounts(counts);
      })

      .catch((error) => {
        console.error("Error fetching orders:", error);
        setDates(null);
        setDate(null);
        setCurrentData(null);
      });
  }, [dispatch]);

  useEffect(() => {
    getOrders();
    // Set up an interval to fetch orders every 30 seconds
    const intervalId = setInterval(getOrders, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [getOrders]);

  useEffect(() => {
    if (ordersFromState) {
      const newDate = Object.keys(ordersFromState)[0];
      setDate(newDate);
      setDates(Object.keys(ordersFromState));
      setCurrentData(ordersFromState);
    }
  }, [ordersFromState]);

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const filteredOrders = (orders) => {
    if (!filterValue) {
      return orders;
    }

    return orders.filter((order) => order.userphone.includes(filterValue));
  };

  const groupOrdersByUserPhone = (orders) => {
    const groupedOrders = orders.reduce((acc, order) => {
      if (!acc[order.userphone]) {
        acc[order.userphone] = [];
      }
      acc[order.userphone].push(order);
      return acc;
    }, {});
    return Object.entries(groupedOrders);
  };

  const calculateTotalAmount = (orders) => {
    let total = 0;
    if (showScreen === "SERVED") {
      const groupedOrders = groupOrdersByUserPhone(orders);
      groupedOrders.forEach(([_, userOrders]) => {
        total += userOrders.reduce((sum, order) => sum + order.amount, 0);
      });
    } else {
      total = orders.reduce((sum, order) => sum + order.amount, 0);
    }
    setTotalAmount(total);
  };

  async function sendAlertToUserWhenMarkOrderCompleted(orders) {
    const alertData = {
      userPhone: orders[0].userphone,
      title: "Review your order",
      body: "Please review give us a feedback on your order",
      url: `https://orderg.in/review/${orders[0]._id}`,
    };
    console.log("Sending alert to user:", alertData);
    try {
      const respone = await sendAlert(alertData);
      console.log("Response after sending the alert", respone);
    } catch (error) {
      console.error("Error sending alert to user:", error);
    }
  }
  function markOrderedCompleted(orders, paymentType = "others") {
    orders.forEach((order) => {
      updateStatus("COMPLETED", order._id)
        .then(() => {
          dispatch(
            updateOrderStatusInState({
              date: new Date(order.createdat)
                .toDateString()
                .split(" ")
                .slice(1)
                .join(" ")
                .toString(),
              status: "COMPLETED",
              prev: "SERVED",
              _id: order._id,
            })
          );
        })
        .catch((error) =>
          console.error("Error marking order as completed:", error)
        );
    });
    sendAlertToUserWhenMarkOrderCompleted(orders);
  }

  function formatDate(isoDateString) {
    let date = new Date(isoDateString);
    let year = date.getUTCFullYear();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    let hours = date.getHours();
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const response = {};
    response.date = `${year}-${month}-${day}`;
    response.time = `${hours}:${minutes} ${ampm}`;
    return response;
  }

  return (
    <div className="w-full flex flex-col items-center">
      <Header />
      <Helmet
        title="Orders"
        meta={[
          {
            name: "description",
            content: "Orders for your cafe",
          },
          {
            property: "og:title",
            content: "Orders",
          },
        ]}
      />
      <Modal
        open={pop}
        onClose={() => setPop(false)}
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        closeIcon={false}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      />
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="flex w-full mb-3 px-2 pt-2 bg-gray-100">
          <div
            id="ORDERED"
            className={`flex items-center h-10 lg:h-12 w-32 lg:w-40 justify-center cursor-pointer rounded-t-lg lg:rounded-t-2xl pt-1 ${
              showScreen === "ORDERED" && "bg-white border border-b-0"
            }`}
            onClick={() => setShowScreen("ORDERED")}
          >
            <p className="text-sm lg:text-base">
              ORDERED ({orderCounts.ORDERED})
            </p>
          </div>
          <div
            id="PREPARING"
            className={`flex items-center h-10 lg:h-12 w-32 lg:w-40 justify-center cursor-pointer rounded-t-lg lg:rounded-t-2xl pt-1 ${
              showScreen === "PREPARING" && "bg-white border border-b-0"
            }`}
            onClick={() => setShowScreen("PREPARING")}
          >
            <p className="text-sm lg:text-base">
              PREPARING ({orderCounts.PREPARING})
            </p>
          </div>
          <div
            id="SERVED"
            className={`flex items-center h-10 lg:h-12 w-32 lg:w-40 justify-center cursor-pointer rounded-t-lg lg:rounded-t-2xl pt-1 ${
              showScreen === "SERVED" && "bg-white border border-b-0"
            }`}
            onClick={() => setShowScreen("SERVED")}
          >
            <p className="text-sm lg:text-base">
              SERVED ({orderCounts.SERVED})
            </p>
          </div>
        </div>
        <div className="flex flex-row w-full h-full ">
          <div className="w-full h-[30%] px-4 mb-4 flex justify-between items-center">
          <Universalinput
              type="text"
              placeholder="Filter by phone number"
              value={filterValue}
              onChange={handleFilterChange}
              className="w-full h-[30%] px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#242424]"
            />
          </div>
          <div className="mr-4" >
            <Link
              to={"/cafe-admin/orders/all"}
              className="w-32 lg:w-40 text-sm  lg:text-base border border-[#FD7347] text-[#FD7347]  bg-white h-9 flex items-center justify-center rounded  cursor-pointer shadow"
            >
               All Orders
            </Link>
          </div>
        </div>

        {!ordersFromState || !date || !ordersFromState[date] ? (
          <img src="../../loading.gif" alt="Loading" />
        ) : (
          <div className="w-full px-4">
            {totalAmount > 0 && (
              <div className="bg-green-100 text-green-800 px-4 py-2 rounded mb-4">
                Total Amount for Filtered Orders: ₹{totalAmount}
              </div>
            )}
            {showScreen === "ORDERED" &&
              (ordersFromState[date] &&
              ordersFromState[date]["ORDERED"] &&
              ordersFromState[date]["ORDERED"].length > 0 ? (
                filteredOrders(ordersFromState[date]["ORDERED"]).map(
                  (order) => <OrdersItem key={order._id} order={order} />
                )
              ) : (
                <p className="text-xl text-center my-8">No Orders Here</p>
              ))}
            {showScreen === "PREPARING" &&
              (ordersFromState[date] &&
              ordersFromState[date]["PREPARING"] &&
              ordersFromState[date]["PREPARING"].length > 0 ? (
                filteredOrders(ordersFromState[date]["PREPARING"]).map(
                  (order) => <OrdersItem key={order._id} order={order} />
                )
              ) : (
                <p className="text-xl text-center my-8">No Orders Here</p>
              ))}
            {showScreen === "SERVED" &&
              (ordersFromState[date] &&
              ordersFromState[date]["SERVED"] &&
              ordersFromState[date]["SERVED"].length > 0 ? (
                groupOrdersByUserPhone(
                  filteredOrders(ordersFromState[date]["SERVED"])
                ).map(([userphone, orders]) => {
                  const userTotal = orders.reduce(
                    (sum, order) => sum + order.amount,
                    0
                  );
                  return (
                    <div
                      key={userphone}
                      className="bg-white shadow-md border rounded-lg p-4 mb-4"
                    >
                      <div className="flex-col mb-4">
                        <div className="flex justify-between items-center mb-2">
                          <p className="text-lg font-semibold">
                            {orders[0].user
                              .split(" ")[0]
                              .slice(0, 1)
                              .toUpperCase() +
                              orders[0].user.split(" ")[0].slice(1) || "Guest"}
                          </p>
                          <p className="text-lg font-semibold">{userphone}</p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex justify-between items-center mb-2 w-full">
                            <div className="flex items-center">
                              <h2 className="text-lg font-semibold">
                                Table:{" "}
                                <span>{orders[0].tablenumber || "N/A"}</span>
                              </h2>
                            </div>
                            <div className="flex-col">
                              <p className="text-sm text-right text-gray-600">
                                Total:{" "}
                                <span className="text-lg text-green-500 font-semibold">
                                  ₹{userTotal}
                                </span>
                              </p>
                              <p className="text-sm text-gray-600">
                                Ordered: {formatDate(orders[0].createdat).time}
                              </p>
                            </div>
                          </div>
                        </div>
                        {orders.length >= 1 && (
                          <button
                            onClick={() => markOrderedCompleted(orders)}
                            className="bg-[#FD7347] text-white rounded w-full h-12 my-4 font-semibold"
                          >
                            {" "}
                            Mark all Paid{" "}
                          </button>
                        )}
                      </div>
                      {orders.map((order, index) => (
                        <div key={order._id} className="mt-4">
                          <OrdersItem
                            order={order}
                            showItems={true}
                            showDetails={index === 0}
                          />
                        </div>
                      ))}
                    </div>
                  );
                })
              ) : (
                <p className="text-xl text-center my-8">No Orders Here</p>
              ))}
          </div>
        )}
      </div>

      <div className="w-full h-20"></div>
      <BottomMenu page="orders" />
    </div>
  );
};

export default Orders;
