
import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import AddItem from "./AddItem";
import { PlusOutlined, MinusOutlined, DeleteOutlined } from "@ant-design/icons";
import AddToCart from "./AddToCart";
import { addToCart, removeFromCart, updateQuantity } from "../redux/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { toggleItemInStock, deleteItem } from "../api/cafe";
import { useTrackedNavigation } from "./analytics/useTrackedNavigation";
import image from "../pages/assets/banner.png";
import { toggleNoteSaved } from "../redux/slices/toggleslice";
const ItemCard = ({ item, isVeg = true, isNonVeg = false, onDelete }) => {
  const dispatch = useDispatch();
  const navigate = useTrackedNavigation();
  const itemimage = item.image
    ? `data:image/png;base64,${item.image}`
    : `${image}`;
  const cartData = useSelector((state) => state.user.cart);

  const [editOpen, setEditOpen] = useState(false);
  const [inStock, setInStock] = useState(item.instock);
  const token = localStorage.getItem("OG_token");

  const onOpenEditModal = () => setEditOpen(true);
  const onCloseEditModal = () => setEditOpen(false);

  const [openAddToCart, setOpenAddToCart] = useState(false);
  const [count, setCount] = useState(0);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);


  function toggleItemStock() {
    setInStock(!inStock);
    toggleItemInStock(item._id);
    dispatch(toggleNoteSaved());
  }


  const Switch = ({ clickAction, toggle }) => {
    const toggleClass = " transform translate-x-5";
    return (
      <div className="flex justify-between items-center p-4">
        <div
          className={`md:w-10 md:h-5 w-10 h-5 flex items-center ${toggle ? "bg-teal-500" : "bg-gray-400"
            } rounded-full p-1 cursor-pointer`}
          onClick={() => {
            clickAction();
          }}
        >
          <div
            className={
              "bg-white md:w-4 md:h-4 h-4 w-4 rounded-full shadow-md transform duration-300 ease-in-out" +
              (toggle ? toggleClass : null)
            }
          ></div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const inCart = cartData.find((food) => food.id === item._id);
    if (inCart) {
      setCount(inCart.quantity);
    }
  }, [cartData]);

  function handleAddToCart(item) {
    if (!item.variant || item.variant.length === 0) {
      setCount(1);
      addToBag();
      return;
    }
    setOpenAddToCart(true);
  }

  function addToBag() {
    const food = {
      variant: "Regular",
      quantity: 1,
      id: item._id,
      name: item.name,
      price: item.price,
      image: item.image,
    };
    dispatch(addToCart(food));
  }

  function removeFromBag() {
    dispatch(removeFromCart(item._id));
  }

  function updateQuantityInBag(quantity) {
    dispatch(updateQuantity({ id: item._id, quantity }));
  }

  function handleIncreaseQuantity() {
    setCount(count + 1);
    updateQuantityInBag(count + 1);
  }

  function handleDecreaseQuantity() {
    setCount(count - 1);
    if (count - 1 === 0) removeFromBag();
    else updateQuantityInBag(count - 1);
  }

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteItem(item._id);
      setDeleteConfirmOpen(false);
      onDelete(item._id); // Call the onDelete function passed from the parent component
    } catch (error) {
      console.error("Error deleting item:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  // Show item if it matches the isVeg value
  const showItem =
    (isVeg && item.veg) ||
    (!isNonVeg && !item.veg) ||
    (!isVeg && isNonVeg);


  if (!showItem) {
    return null; // Don't render the item if it doesn't match the isVeg filter
  }

  return (
    <div
      key={item._id}
      className={`flex flex-col justify-between items-center my-6 w-full mt-4`}
    >
      <Modal
        open={editOpen}
        onClose={onCloseEditModal}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
        styles={{
          modal: {
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
          }
        }}
      >
        <AddItem closeModal={onCloseEditModal} oldItem={item} />
      </Modal>
      <AddToCart open={openAddToCart} setOpen={setOpenAddToCart} item={item} />
      <Modal
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this item?</p>
        <div className="flex justify-end mt-4">
          <button
            onClick={() => setDeleteConfirmOpen(false)}
            className="mr-2 px-4 py-2 bg-gray-300 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded"
          >
            Delete
          </button>
        </div>
      </Modal>
      <div
        className={`flex w-full justify-between items-center relative ${inStock ? "cursor-pointer" : "cursor-not-allowed opacity-50"
          }`}
      >
        <div>
          <div className="flex items-center">
            {item.veg ? (
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgUlEQVR4nM2S0QmAMAxE+51Mpu5gspIgXsURHMtBlICKaMWIiB7cT2heyiUh/EYCHiRS7zJ4OAIi9e5hqbeyKyooV1A1O78FUDA08ri1gBoXQG3yrnmFtFR4ANUpANy9Dyhbys4AS5iXIQqoSTTX7i2YLDD7svn2GsOF0oCnp/yZJmb+qB4yMqyCAAAAAElFTkSuQmCC"
                alt="vegetarian-food-symbol"
              />
            ) : (
              <img
                width="16"
                height="16"
                src="https://img.icons8.com/color/48/94D82D/non-vegetarian-food-symbol.png"
                alt="non-vegetarian-food-symbol"
              />
            )}
          </div>
          <h2 className={`text-xl pt-1 capitalize  ${item.veg ? "text-[#3E4462]" : "text-[#70727a]"} `}>
            {item.name}
          </h2>
          <h2 className={`text-md font-semibold capitalize  ${item.veg ? "text-black" : "text-[#4e4e4e]"} `}>
            {item.price ? `₹${item.price}` : ''}
          </h2>
          <p className={`text-xs  ${item.veg ? "text-black" : "text-[#4e4e4e]"} pt-2 w-[250px]`} style={{ width: '250px' }}>
            {item.description}
          </p>
        </div>
        <div
          className={`${item.image ? "h-28" : "h-28"
            } w-24 flex flex-col items-center relative`}
        >
          {!inStock && (
            <div className="absolute px-2 py-1 rounded-full bg-red-500 text-xs text-white font-bold top-2">
              Out of Stock
            </div>
          )}
          {item.image && (
            <img
              src={itemimage}
              alt={item.name}
              className="object-cover h-full w-full rounded-[8px] "
            />
          )}
        </div>
      </div>

      {token && (
        <div className="flex flex-col w-full justify-between items-center">
          <div className="flex w-full mx-4 items-center justify-around">
            <Switch toggle={inStock} clickAction={toggleItemStock} />
            {inStock ? (
              <div className="text-blue-500 w-[150px] flex items-center">
                In stock
              </div>
            ) : (
              <div className="text-red-500 w-[150px] flex items-center">
                Out of stock
              </div>
            )}
            <button
              onClick={onOpenEditModal}
              className="text-sky-500 p-2 block rounded-md"
            >
              Edit item
            </button>
            <button
              onClick={handleDeleteClick}
              className="text-red-500 p-2 block rounded-md"
            >
              <DeleteOutlined /> Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemCard;
