import React from "react";
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const LoadingSpinner = () => (
  <div className="w-full h-screen flex flex-col items-center justify-center">
    <img src="../loading.gif" alt="Loading" />
  </div>
);

const Rejected = () => {
  const cafeData = useSelector((state) => state.user.cafe);

  if (!cafeData) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full h-screen flex flex-col items-center">
      <Helmet
        title={`${cafeData.name} Menu`}
        meta={[
          {
            name: "description",
            content: "Your cafe menu",
          },
          {
            property: "og:title",
            content: "Menu",
          },
        ]}
      />
      {cafeData.image && (
        <div className="h-80 w-full fixed top-0 flex flex-col justify-center items-center bg-black">
          <img
            src={`data:image/png;base64,${cafeData.image}`}
            alt="banner"
            className="w-full h-80 object-cover absolute top-0 z-0"
          />
        </div>
      )}
      <div className={`w-full px-4 ${cafeData.image ? "mt-60" : ""} pt-4 relative z-20 rounded-t-2xl bg-white flex items-center flex-col justify-center`}>
        <div className="h-1 w-20 px-8 rounded-3xl bg-gray-400 mx-auto"></div>
        <h1 className="text-xl text-center font-bold mt-4 opacity-50">
          {cafeData.name}
        </h1>
        <h1 className="text-2xl text-center mt-10">Your order can't be fulfilled at the moment</h1>
        <img className='mt-10' src="../sorry.png" alt="Closed Banner" />
        <p className='px-16 text-center'>Please try again later or contact reception</p>
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  message: PropTypes.string,
};

export default React.memo(Rejected);