import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import itemsReducer from "./slices/cafe";
import userReducer from "./slices/user";
import adminReducer from "./slices/admin";
import {toggleReducer} from "./slices/toggleslice"
export default configureStore({
  reducer: {
    auth: authReducer,
    cafe: itemsReducer,
    user: userReducer,
    admin: adminReducer,
    bool: toggleReducer,
  },
  preloadedState: {
    user: {
      cart: JSON.parse(sessionStorage.getItem("OG_bag")) || [],
      cafe: JSON.parse(sessionStorage.getItem("OG_cafe")) || [],
      order: JSON.parse(sessionStorage.getItem("OG_order")) || [],
    },
  },
});
