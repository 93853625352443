import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { fetchOrder } from '../../api/user';
import { LeftOutlined } from '@ant-design/icons';
import LoadingSpinner from './LoadingSpinner';
import PropTypes from 'prop-types';

const Invoice = () => {
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const cafe = useSelector((state) => state.user.cafe);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const res = await fetchOrder(id);
        setOrder(res.data);
      } catch (error) {
        console.error("Error fetching order:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderData();
  }, [id]);

  const formatDate = useCallback((isoDateString) => {
    const date = new Date(isoDateString);
    return {
      date: `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`,
      time: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
    };
  }, []);

  const calculateSGST = useCallback((amount) => Math.floor(amount * 5 / 100), []);
  const calculateCGST = useCallback((amount) => Math.floor((amount - calculateSGST(amount)) * 18 / 100), [calculateSGST]);
  const calculateServiceCharges = useCallback((amount) => Math.floor((amount - calculateSGST(amount) - calculateCGST(amount)) * 10 / 100), [calculateSGST, calculateCGST]);

  const OrderDetail = ({ label, value }) => (
    <div className='flex justify-between w-full items-center py-1'>
      <span className='text-sm font-normal'>{label}:</span>
      <span className='font-semibold'>{isLoading ? <LoadingSpinner /> : <div>{value}</div>}</span>
    </div>
  );

  OrderDetail.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  return (
    <div className="w-full bg-gray-100 h-screen flex flex-col items-center">
      <div className='w-full flex justify-between items-center p-4 text-xl font-semibold bg-black text-white'>
        <img src="../../Icon.svg" alt="OG" className='mr-1 h-10' />
        <h1>{cafe.name}</h1>
      </div>

      <div className='w-full text-sm flex flex-col items-center py-6 justify-between px-4 border-b-2 border-dashed'>
        <Link to={`/order/bill/${id}`} className='text-left flex items-center font-bold mb-4 text-orange-500'>
          <LeftOutlined /> Go Back
        </Link>
        <OrderDetail label="Table Number" value={order.tablenumber} />
        <OrderDetail label="Order Amount" value={`₹${order.amount}`} />
        <OrderDetail label="Order Date" value={formatDate(order.createdat).date} />
      </div>

      <div className='w-full text-lg text-left flex-col font-semibold flex items-center justify-center py-6 px-4'>
        <h1 className='text-xl mb-10'>Your order Items</h1>
        {order?.items?.map((item, index) => (
          <div key={index} className='flex w-full justify-between border-b border-gray-400 py-4 border-dashed'>
            <p>{item.name} ({item.quantity}) </p>
            <p>₹{item.price}</p>
          </div>
        ))}
      </div>

      <div className='w-full text-lg text-left flex-col font-semibold flex items-center justify-center py-6 px-4'>
        <h1 className='text-xl mb-10'>Your order Summary</h1>
        <OrderDetail label="Order Total" value={`₹${order.amount}`} />
        <OrderDetail label="Order SGST (5%)" value={`₹${calculateSGST(order.amount)}`} />
        <OrderDetail label="Order CGST (18%)" value={`₹${calculateCGST(order.amount)}`} />
        <OrderDetail label="Service Charges (10%)" value={`₹${calculateServiceCharges(order.amount)}`} />
      </div>

      <p className='text-xl text-center font-semibold mb-8'>Thank You! Visit again</p>

      <div className='w-full text-left bg-black text-white text-sm flex justify-between px-4 py-8 border-b-2 border-dashed'>
        <div className='w-1/2 flex flex-col items-center text-left'>
          <p className='font-bold'>{cafe.name}</p>
          <p>{cafe.address}</p>
          <p>{cafe.city}, {cafe.pincode}</p>
          <p>{cafe.state}</p>
        </div>
        <div className='w-1/2 flex flex-col items-center text-left'>
          <p className='font-bold'>Powered By:</p>
          <p className='text-orange-500 font-bold'>OrderG</p>
          <p>+91 7007992740</p>
          <p>{cafe.state}</p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Invoice);