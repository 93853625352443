import React, { useEffect, useRef, useState,memo,useCallback } from "react";
import { FaClock, FaThumbsUp } from "react-icons/fa";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { getFacilities, getOperatingHours } from "../../api/cafe";
import {
  getActiveOffers,
  getAllActiveOffers,
  getCafeDetails,
  getCafeItemsByCategory,
  getGalleryImages,
} from "../../api/user";
import LoadingOverlay from "../../components/LoadingOverlay";
import StarRating from "../../components/StarRating";
import Contact from "../../components/frontpage/contact";
import RatingCard from "../../components/frontpage/review";
import Offercard from "../../components/user/offerCard";
import swiggy from "../assets/Group 18291.svg";
import zomato from "../assets/image 52.svg";

import { Link as NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { getPublicCafeReviewStats } from "../../api/cafe";
import insta from "../assets/lets-icons_insta.svg";
import facebook from "../assets/mingcute_facebook-line.svg";
import globe from "../assets/ph_globe.svg";

import { useDispatch, useSelector } from "react-redux";
import ItemCard from "../../components/ItemCard";

import { CloseOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons";
import { recordAnalyticsEvent } from "../../api/analytics";
import Universalinput from "../../components/Universalnput";
import { useTrackedNavigation } from "../../components/analytics/useTrackedNavigation";
import OfferPopup from "../../components/user/OfferPopup";
import { FixedSizeList as List } from 'react-window';


const AllActiveOffers = () => {
  const [allOffers, setAllOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [error, setError] = useState(null);
  const [cafeDetails, setCafeDetails] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [cafeIndex, setCafeIndex] = useState(null);

  useEffect(() => {
    sessionStorage.removeItem("selected_cafe_id");

    const fetchOffers = async () => {
      try {
        const response = await getAllActiveOffers();
        setAllOffers(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching offers:", err);
        setError("Failed to fetch offers");
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  const handleViewProfile = useCallback(async (cafeId) => {
    console.log("selected cafe:", cafeId);
    setShowProfileModal(true);

    try {
      const response = await getCafeDetails(cafeId);
      const opHrs = await getOperatingHours(cafeId);
      const galleryImages = await getGalleryImages(cafeId);
      const activeOffers = await getActiveOffers(cafeId);
      const facilities = await getFacilities(cafeId);
      const cafeMenu = await getCafeItemsByCategory(cafeId);
      const newCafeDetails = {
        ...response.data,
        operating_hours: opHrs.data,
        gallery_images: galleryImages.data,
        offers: activeOffers.data,
        facilities: facilities.data,
        items: cafeMenu.data,
        _id: cafeId,
      };
      console.log("newCafeDetails:", newCafeDetails);
      setCafeDetails(newCafeDetails);
    } catch (err) {
      console.error("Error fetching cafe details:", err);
      setError("Failed to fetch cafe details");
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowProfileModal(false);
    setCafeDetails(null);
  }, []);

  const handleViewMenu = useCallback(() => {
    setShowMenuModal(true);
    setShowProfileModal(false);
  }, []);

  const handleCloseMenuModal = useCallback(() => {
    setShowMenuModal(false);
    setShowProfileModal(true);
  }, []);

  const handleOfferClick = useCallback((offer, index) => {
    setCafeIndex(index);
    setSelectedOffer(offer);
    const offerLink = `${window.location.origin}/cafe/offer/${offer.id}`;
    recordAnalyticsEvent("offer_click", "ActiveOffers", {
      offer_id: offer.id,
      offer_title: offer.title,
      offer_type: offer.type,
      link: offerLink,
    });
  }, []);

  const renderOffer = ({ index, style }) => {
    const cafe = allOffers[index];
    return (
      <div
        key={cafe.cafeId}
        className="bg-white rounded-lg shadow-md p-4 w-full h-full"
        style={style}
      >
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-bold">{cafe.cafeName}</h2>
          <button
            onClick={() => handleViewProfile(cafe.cafeId)}
            className=" text-black hover:bg-blue-700  font-bold py-2 px-4 rounded"
          >
            View cafe Details
          </button>
        </div>
        <div className="flex items-center gap-x-3 overflow-x-auto">
          {cafe?.offers?.length > 0 &&
            cafe.offers
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((offer, index) => (
                <div onClick={() => handleOfferClick(offer, index)}>
                  {offer.type === "card" ? (
                    <div key={offer.id} className="w-full">
                      <Offercard offer={offer} cafeName={cafe.cafeName} />
                    </div>
                  ) : (
                    <div className="border-2 h-1/2 border-white px-2 pt-1 rounded-lg shadow-lg">
                      <div className="w-[250px]  aspect-video rounded-lg shadow-md overflow-hidden">
                        <img
                          src={
                            offer.posterImage
                              ? `data:image/webp;base64,${offer.posterImage}`
                              : `${offer.imageUri}`
                          }
                          alt={`Offer ${index + 1}`}
                          className="w-full object-cover"
                        />
                      </div>
                      <div className="flex items-center justify-center gap-x-6">
                        {offer.addToSwiggy === "true" && (
                          <img src={swiggy} alt="Swiggy" className="w-24 h-24" />
                        )}
                        {offer.addToZomato === "true" && (
                          <img src={zomato} alt="Zomato" className="w-24 h-24" />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
        </div>
      </div>
    );
  };

  if (loading) return <LoadingOverlay show={loading} />;
  if (error) return <div>{error}</div>;

  return (
    <div className="p-4 w-full">
      <h1 className="text-3xl font-bold mb-4">All Active Offers</h1>
      <div className="flex flex-col gap-4 w-full h-full">
        {allOffers.length === 0 && (
          <div className="flex justify-center items-center h-full">
            <h2 className="text-xl font-bold">
              No cafe nearby is offering any discounts at this moment, Allow
              notifications and stay updated with latest offers
            </h2>
          </div>
        )}
        {allOffers.length > 0 && (
          <List
            height={600}
            itemCount={allOffers.length}
            itemSize={200}
            width="100%"
          >
            {renderOffer}
          </List>
        )}
      </div>
      {selectedOffer && (
        <OfferPopup
          offer={selectedOffer}
          onClose={() => {
            setSelectedOffer(null);
            setCafeIndex(null);
          }}
          cafeName={allOffers[cafeIndex].cafeName}
        />
      )}
      {showProfileModal && (
        <Modal
          open={showProfileModal}
          onClose={handleCloseModal}
          classNames={{
            modal:
              "w-[90%]  custom-modal-class h-full sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2 max-w-3xl  overflow-hidden  ",
            overlay: "custom-overlay",
          }}
          center
        >
          <div className="h-full overflow-y-auto">
            <CafeProfileModal
              cafeDetails={cafeDetails}
              onViewMenu={handleViewMenu}
            />
          </div>
        </Modal>
      )}
      {showMenuModal && (
        <Modal
          open={showMenuModal}
          onClose={handleCloseMenuModal}
          classNames={{
            modal:
              "w-[90%] custom-modal-class h-full sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2overflow-hidden ",
            overlay: "custom-overlay",
          }}
          center
        >
          <div className="h-full overflow-y-auto">
            <MenuModal
              cafeDetails={cafeDetails}
              onBack={handleCloseMenuModal}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AllActiveOffers;
const CafeProfileModal = memo(({ cafeDetails, onViewMenu }) => {
  const [reviewStats, setReviewStats] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (cafeDetails) {
      loadCafeProfile(cafeDetails._id);
    }
  }, [cafeDetails]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    let startY;

    const touchStart = (e) => {
      startY = e.touches[0].pageY;
    };

    const touchMove = (e) => {
      if (startY === undefined) return;

      const currentY = e.touches[0].pageY;
      const diffY = startY - currentY;
      
      if (
        (scrollContainer.scrollTop === 0 && diffY < 0) ||
        (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight && diffY > 0)
      ) {
        e.preventDefault();
      }

      scrollContainer.scrollTop += diffY;
      startY = currentY;
    };

    scrollContainer.addEventListener('touchstart', touchStart);
    scrollContainer.addEventListener('touchmove', touchMove);

    return () => {
      scrollContainer.removeEventListener('touchstart', touchStart);
      scrollContainer.removeEventListener('touchmove', touchMove);
    };
  }, [scrollContainerRef.current]); // This effect runs when the ref is attached to a DOM element

  const loadCafeProfile = async (cafeId) => {
    try {
      const reviewStats = await getPublicCafeReviewStats(cafeId);
      setReviewStats(reviewStats.data || null);
    } catch (error) {
      console.error("Error fetching cafe profile information:", error);
      toast.error("Failed to fetch cafe profile information");
    }
  };

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    const offerLink = `${window.location.origin}/cafe/offer/${offer.id}`;
    recordAnalyticsEvent("offer_click", "CafeProfile", {
      offer_id: offer.id,
      offer_title: offer.title,
      offer_type: offer.type,
      link: offerLink,
    });
  };

  if (!cafeDetails) return null;

  return (
    <div 
      ref={scrollContainerRef}
      className="w-full h-full overflow-y-scroll overscroll-y-contain" 
      style={{ 
        WebkitOverflowScrolling: 'touch',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <div className="relative w-full h-[200px] mb-4">
        <img
          src={`data:image/webp;base64,${cafeDetails.image}`}
          alt="Banner"
          className="w-full h-full object-cover"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-black bg-opacity-30 h-full w-full"></div>
        <div className="absolute bottom-4 left-4 font-medium text-2xl text-white">
          {cafeDetails.name}
        </div>
      </div>

      <div className="sticky top-0 h-[40px] bg-white flex items-center z-50 px-5">
        <nav className="overflow-x-auto w-full z-1000">
          <ul className="flex flex-row gap-2 items-center text-black w-full">
            {["About", "Menu", "Social", "OrderOnline", "Offer", "Gallery", "Reviews", "Contact"].map((item) => (
              <li key={item}>
                <Link
                  to={item}
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-40}
                  activeClass="text-purple-500"
                  className="cursor-pointer transition-colors duration-200 text-black text-xs font-bold"
                >
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <section
        id="About"
        className="px-4 mb-4"
      >
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <FaClock size={24} />
            <div className="ml-2">
              <span className="block text-xs text-gray-500">Currently</span>
              <span className="block text-lg font-bold">
                {cafeDetails.openstatus ? "Open" : "Closed"}
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <FaThumbsUp size={24} />
            <div className="ml-2">
              {reviewStats?.totalReviews > 0 && (
                <StarRating
                  rating={reviewStats.averageRating}
                  count={reviewStats.totalReviews}
                />
              )}
              {
                <div className="text-2xl  font-bold mb-2">
                  {reviewStats?.totalReviews > 0
                    ? `${reviewStats.averageRating} (${
                        reviewStats.totalReviews
                      })`
                    : "No Reviews"}
                </div>
              }
            </div>
          </div>
        </div>
        <p>{cafeDetails.description}</p>
      </section>

          <section id="Menu" className="px-4 mb-4">
            <h2 className="font-bold text-lg mb-2">Menu</h2>
            <button
              onClick={onViewMenu}
              className="w-full mt-2 py-2 bg-purple-500 text-white rounded-lg font-bold"
            >
              View Menu
            </button>
          </section>

      <hr className="border-t border-gray-300 my-4" />
      <section
        id="Social"
        className="social px-5 mb-4"
      > 
        {(cafeDetails.website ||
          cafeDetails.instagram_link ||
          cafeDetails.facebook_link) && (
          <>
            <div className="font-bold text-lg mb-8">Social</div>
            <div className="flex flex-col mt-4 space-y-4">
              {cafeDetails.website && (
                <div className="flex items-center justify-between">
                  <NavLink
                    to={cafeDetails.website}
                    className="flex flex-row gap-2 cursor-pointer"
                  >
                    <img
                      src={globe}
                      alt="Globe"
                    />
                    <div className="w-full text-black hover:underline">
                      {cafeDetails.website}
                    </div>
                  </NavLink>
                </div>
              )}

              {cafeDetails.instagram_link && (
                <NavLink
                  to={cafeDetails.instagram_link}
                  className="flex flex-row gap-2 cursor-pointer"
                >
                  <img
                    src={insta}
                    alt="Instagram"
                  />
                  <div className="w-full text-black hover:underline">
                    {cafeDetails.instagram_link}
                  </div>
                </NavLink>
              )}

              {cafeDetails.facebook_link && (
                <NavLink
                  to={cafeDetails.facebook_link}
                  className="flex flex-row gap-2"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                  />
                  <div className="w-full text-black hover:underline">
                    {cafeDetails.facebook_link}
                  </div>
                </NavLink>
              )}
            </div>
          </>
        )}

        {Object.keys(cafeDetails.other_social_links).length > 0 && (
          <>
            <div className="font-bold text-lg mt-8">Other Social links</div>
            <div className="flex flex-col mt-4 space-y-4">
              {Object.entries(cafeDetails.other_social_links).map(
                ([key, value]) => (
                  <NavLink
                    key={key}
                    to={value}
                    className="w-full flex flex-row gap-2 cursor-pointer"
                  >
                    <div className="text-black capitalize font-bold">{key}</div>
                    <div className="text-black hover:underline">{value}</div>
                  </NavLink>
                )
              )}
            </div>
          </>
        )}
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="OrderOnline"
        className=" social px-5 mb-4"
      >
        <div className="font-bold text-lg mb-8">Order Online</div>
        <div className="flex items-center justify-between  mt-4 gap-x-4">
          <NavLink
            to={cafeDetails.swiggy_link}
            className="flex-grow"
          >
            <button className="w-full h-10 flex items-center justify-center rounded-lg  shadow-md">
              <img
                src={swiggy}
                className="w-20 h-20 object-contain"
                alt="Swiggy"
              />
            </button>
          </NavLink>

          <NavLink
            className="flex-grow"
            to={`tel:${cafeDetails.phone}`}
          >
            <button
              className="w-full h-10 flex items-center justify-center bg-purple-500 rounded-lg text-white shadow-md"
              style={{ boxShadow: "2px 3px 0px 0px #000000" }}
            >
              Call Us
            </button>
          </NavLink>

          <NavLink
            to={cafeDetails.zomato_link}
            className="flex-grow"
          >
            <button className="w-full h-10 flex items-center justify-center rounded-lg  shadow-md">
              <img
                src={zomato}
                className="w-20 h-20 object-contain"
                alt="Zomato"
              />
            </button>
          </NavLink>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />
      <section
        id="Offer"
        className="px-4 mb-4"
      >
        <h2 className="font-bold text-lg mb-2">Offers</h2>
        <div className="flex overflow-x-auto gap-4 pb-4">
          {cafeDetails.offers.length > 0 ? (
            <div className="flex overflow-x-auto gap-4 pb-4">
              <div className="flex gap-4 flex-nowrap">
                {cafeDetails?.offers
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((offer, index) => (
                    <div
                      key={index}
                      className="flex-shrink-0"
                      onClick={() => handleOfferClick(offer)}
                    >
                      {offer.type === "card" ? (
                        <div
                          key={offer.id}
                          className="w-full  h-full object-cover"
                        >
                          <Offercard
                            offer={offer}
                            cafeName={cafeDetails.name}
                          />
                        </div>
                      ) : (
                        <div className="border-2 py-2 w-[320px] h-auto border-white px-2 pt-1 rounded-lg shadow-lg">
                          <div className="w-full rounded-lg shadow-md overflow-hidden">
                            <img
                              src={
                                offer.posterImage
                                  ? `data:image/webp;base64,${offer.posterImage}`
                                  : `${offer.imageUri}`
                              }
                              alt={`Offer ${index + 1}`}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="flex items-center justify-center gap-x-6">
                            {offer.addToSwiggy === "true" && (
                              <img
                                src={swiggy}
                                alt="Swiggy"
                                className="w-10 h-24"
                              />
                            )}
                            {offer.addToZomato === "true" && (
                              <img
                                src={zomato}
                                alt="Zomato"
                                className="w-24 h-24"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <p>No active offers at the moment.</p>
          )}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Gallery"
        className="px-4 mb-4"
      >
        <h2 className="font-bold text-lg mb-2">Gallery</h2>
        <div className="flex overflow-x-auto gap-4 pb-4">
          {cafeDetails.gallery_images &&
            cafeDetails.gallery_images.map((image, index) => (
              <img
                key={index}
                src={`data:image/webp;base64,${image}`}
                alt={`Gallery image ${index + 1}`}
                className="w-20 h-20 object-cover rounded flex-shrink-0"
              />
            ))}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Reviews"
        className="px-4 mb-4"
      >
        <h2 className="font-bold text-lg mb-2">Reviews</h2>
        <div className="overflow-x-auto whitespace-nowrap">
          {reviewStats &&
            reviewStats.questionStats?.map((question) => (
              <div
                key={question?.question_id}
                className="rounded-lg p-2 inline-block w-[80%]"
              >
                <RatingCard
                  rating={question?.average_rating}
                  count={question?.number_of_ratings}
                  question={question?.question_text}
                />
              </div>
            ))}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Contact"
        className="px-4 mb-4"
      >
        <h2 className="font-bold text-lg mb-2">Contact</h2>

        <Contact cafeDetails={cafeDetails} />
      </section>
      {selectedOffer && (
        <OfferPopup
          offer={selectedOffer}
          onClose={() => {
            setSelectedOffer(null);
          }}
          cafeName={cafeDetails.name}
        />
      )}
    </div>
  );
});

const MenuModal = ({ cafeDetails, onBack }) => {
  console.log("first:", cafeDetails);
  const { cafe } = cafeDetails;
  const dispatch = useDispatch();
  const navigate = useTrackedNavigation();

  const menuData = useSelector((state) => state.user.menu);
  const cartData = useSelector((state) => state.user.cart);

  const [items, setItems] = useState({});
  const [isVeg, setIsVeg] = useState(false);
  const [isNonVeg, setIsNonVeg] = useState(true);
  const [subcategories, setSubcategories] = useState({});
  const [openSubcategories, setOpenSubcategories] = useState({});
  const [search, setSearch] = useState("");
  const [sortedCategories, setSortedCategories] = useState([]);

  const categoryRefs = useRef({});

  useEffect(() => {
    recordAnalyticsEvent("page_view", "UserMenu", {
      scroll_depth: 0,
      session_duration: 0,
    });
  }, []);

  function loadItems() {
    organizeItemsBySubcategory(cafeDetails.items);
  }

  function organizeItemsBySubcategory(menuData) {
    const subcats = {};
    let recommendedItems = [];
    
    // Sort categories by order
    const sorted = Object.entries(menuData)
      .filter(([category, items]) => category !== "All" && Array.isArray(items) && items.length > 0)
      .sort(([,a], [,b]) => {
        const orderA = a[0]?.category_order ?? Number.MAX_SAFE_INTEGER;
        const orderB = b[0]?.category_order ?? Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      });

    sorted.forEach(([category, categoryItems]) => {
      if (category === "Recommended") {
        recommendedItems = [...categoryItems].sort((a, b) => (a.item_order || 0) - (b.item_order || 0));
      } else {
        // Group items by subcategory
        const groupedItems = categoryItems.reduce((acc, item) => {
          const subcategory = item.subcategory || 'Other';
          if (!acc[subcategory]) {
            acc[subcategory] = [];
          }
          acc[subcategory].push(item);
          return acc;
        }, {});

        // Sort subcategories and items within each subcategory
        subcats[category] = Object.entries(groupedItems)
          .sort(([,a], [,b]) => {
            const orderA = a[0]?.subcategory_order ?? Number.MAX_SAFE_INTEGER;
            const orderB = b[0]?.subcategory_order ?? Number.MAX_SAFE_INTEGER;
            return orderA - orderB;
          })
          .reduce((acc, [subcategory, items]) => {
            acc[subcategory] = items.sort((a, b) => (a.item_order || 0) - (b.item_order || 0));
            return acc;
          }, {});
      }
    });

    setSubcategories(subcats);
    setSortedCategories(sorted.map(([category]) => category));

    const openSubcats = {};
    Object.keys(subcats).forEach((category) => {
      openSubcats[category] = {};
      Object.keys(subcats[category]).forEach((subcat) => {
        openSubcats[category][subcat] = true;
      });
    });
    setOpenSubcategories(openSubcats);

    // Update items state with sorted data
    const sortedItems = Object.fromEntries(sorted);
    if (recommendedItems.length > 0) {
      sortedItems["Recommended"] = recommendedItems;
    }
    setItems(sortedItems);
  }

  useEffect(() => {
    if (cafeDetails && cafeDetails.items) {
      organizeItemsBySubcategory(cafeDetails.items);
    }
  }, [cafeDetails]);

  const toggleSubcategory = (cat, subcat) => {
    setOpenSubcategories((prev) => ({
      ...prev,
      [cat]: {
        ...prev[cat],
        [subcat]: !prev[cat]?.[subcat],
      },
    }));
  };

  const scrollToCategory = (category) => {
    const element = categoryRefs.current[category];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const renderItems = () => {
    if (search.length > 0) {
      const filteredItems = Object.entries(items).flatMap(([category, categoryItems]) => 
        categoryItems.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
      );
      return (
        <div className="pl-4">
          {filteredItems.map((item) => (
            <ItemCard
              key={item._id}
              item={item}
              isVeg={isVeg}
              isNonVeg={isNonVeg}
            />
          ))}
        </div>
      );
    }

    return sortedCategories.map((category) => (
      <div key={category} className="mb-8" ref={el => categoryRefs.current[category] = el}>
        <h2 className="text-xl font-bold mb-4">{category}</h2>
        {Object.entries(subcategories[category] || {}).map(([subcat, subcatItems]) => (
          <div key={subcat} className="w-full mb-4">
            {subcat !== 'Other' && (
              <button
                className="w-full text-left px-1 py-2 text-[#3E4462] text-sm font-semibold flex justify-between items-center capitalize"
                onClick={() => toggleSubcategory(category, subcat)}
              >
                {subcat}
                <DownOutlined
                  className={`transform transition-transform ${
                    openSubcategories[category]?.[subcat] ? "rotate-180" : "rotate-0"
                  }`}
                />
              </button>
            )}
            {openSubcategories[category]?.[subcat] && (
              <div className="pl-4">
                {subcatItems.map((item) => (
                  <ItemCard
                    key={item._id}
                    item={item}
                    isVeg={isVeg}
                    isNonVeg={isNonVeg}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div className="container mx-auto overflow-y-auto max-h-[90vh] p-4">
      {cafeDetails.image && (
        <div className="relative w-full h-80">
          <img
            src={`data:image/png;base64,${cafeDetails?.image}`}
            alt="banner"
            className="w-full h-80 object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-30 h-full w-full"></div>
          <div className="absolute bottom-4 left-4 z-20">
            <h1 className="text-3xl font-bold text-white">{cafeDetails.name}</h1>
            <StarRating
              rating={cafeDetails.rating}
              count={cafeDetails.reviewcount}
              size={"big"}
              className="mt-2"
            />
          </div>
        </div>
      )}

      <div className="w-full px-4 pt-4 flex flex-col items-start justify-center">
        <div className="w-full sticky top-0 z-30 bg-[#FAFAFA] pb-4">
          <div className="py-2 relative">
            <Universalinput
              type="text"
              placeholder="What to eat today?"
              value={search}
              className="w-full h-[40px]"
              onChange={(e) => setSearch(e.target.value)}
              icon={SearchOutlined}
            />
            {search.length > 0 && (
              <CloseOutlined
                onClick={() => setSearch("")}
                className="absolute right-6 top-1/2 transform -translate-y-1/2"
              />
            )}
          </div>
          <div className="flex flex-row gap-4 mt-2 mb-4 w-full justify-start">
            <button
              onClick={() => setIsVeg(!isVeg)}
              className={`flex items-center px-3 py-1 rounded-[10px] border text-xs border-[#000000] ${
                isVeg ? "bg-[#9277FF] text-white" : "bg-white text-gray-700"
              }`}
              style={{ boxShadow: "2px 2px 0px 0px #000000" }}
            >
              <div className={`w-4 h-4 rounded-full mr-2 border border-green-400`}>
                <div className={`w-2 h-2 rounded-[10px] mt-[3px] ml-[3px] ${"bg-green-400"}`}></div>
              </div>
              Veg
            </button>
            <button
              onClick={() => setIsNonVeg(!isNonVeg)}
              className={`flex items-center px-3 py-1 rounded-[10px] border text-xs border-[#000000] ${
                isNonVeg ? "bg-white text-gray-700" : "bg-[#9277FF] text-white"
              }`}
              style={{ boxShadow: "2px 2px 0px 0px #000000" }}
            >
              <div className={`w-4 h-4 rounded-full mr-2 border border-[#b00000]`}>
                <div className={`w-2 h-2 rounded-[10px] mt-[3px] ml-[3px] ${"bg-[#b00000]"}`}></div>
              </div>
              Non-Veg
            </button>
          </div>
          <div className="w-full mb-1 flex flex-col gap-4">
            <div className="w-full flex gap-2 flex-nowrap overflow-x-auto">
              {sortedCategories.map((cat) => (
                <button
                  key={cat}
                  onClick={() => scrollToCategory(cat)}
                  className="whitespace-nowrap px-4 py-2 rounded-full border text-xs capitalize border-gray-400 text-gray-700"
                >
                  {cat}
                </button>
              ))}
            </div>
          </div>
        </div>

        {renderItems()}
      </div>

      {cartData.length > 0 && (
        <div className="flex flex-col fixed bottom-16 left-0 right-0 py-4 bg-white w-full items-center">
          <Link
            to={"/cafe/bag"}
            className="flex items-center justify-center mx-4 w-80 h-16 bg-[#FD7347] text-white py-2 px-4 rounded-2xl"
          >
            Go to Bag
          </Link>
        </div>
      )}
    </div>
  );
};