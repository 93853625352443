import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
};

const adminSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    updateOrderStatusInState(state, action) {
      if (state.orders[action.payload.date][action.payload.prev]) {
        const index = state.orders[action.payload.date][
          action.payload.prev
        ].findIndex((order) => order._id === action.payload._id);
        if (!state.orders[action.payload.date][action.payload.status])
          state.orders[action.payload.date][action.payload.status] = [];
        state.orders[action.payload.date][action.payload.prev][index].status =
          action.payload.status;
        state.orders[action.payload.date][action.payload.status].push(
          state.orders[action.payload.date][action.payload.prev][index]
        );
        state.orders[action.payload.date][action.payload.prev] = state.orders[
          action.payload.date
        ][action.payload.prev].filter(
          (item) => item._id !== action.payload._id
        );
      }
    },
  },
});

export const { setOrders, updateOrderStatusInState } = adminSlice.actions;
export default adminSlice.reducer;
