import React from 'react';
import PropTypes from 'prop-types';
import arrow from '../assets/Arrow 3.svg';

const InfoCard = ({ message }) => (
  <div className="w-full h-[40px] bg-[#00F3AE] rounded-lg font-semibold text-md flex gap-2 pl-2 items-center" style={{ boxShadow: "1px 2px 2px 2px #000000" }}>
    <div className="w-[25px] h-[25px] rounded-full bg-white flex justify-center items-center">
      <img src={arrow} alt="Arrow" />
    </div>
    <span className="text-xs">{message}</span>
  </div>
);

InfoCard.propTypes = {
  message: PropTypes.string.isRequired,
};

const Home = () => {
  return (
    <div className="px-4 mb-4 flex flex-col gap-4">
      <div className="font-bold text-lg mt-4">Your business at a glance</div>
      <InfoCard message="You reached 50 new people this week" />
      <InfoCard message="Some insight" />
    </div>
  );
};

export default React.memo(Home);