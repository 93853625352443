import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getFoodcourtCafes, getFoodcourtDetails } from '../../api/foodcourt';
import CafeCard from './CafeCard';

const FoodcourtCafeList = () => {
  const [cafes, setCafes] = useState([]);
  const [foodcourt, setFoodcourt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { foodcourtId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!foodcourtId) {
      navigate('/404');
      return;
    }

    const fetchData = async () => {
      try {
        const [cafeResponse, foodcourtResponse] = await Promise.all([
          getFoodcourtCafes(foodcourtId),
          getFoodcourtDetails(foodcourtId)
        ]);
        
        if (cafeResponse && cafeResponse.data && Array.isArray(cafeResponse.data)) {
          setCafes(cafeResponse.data);
        } else {
          console.error('Invalid cafe data structure:', cafeResponse);
          setError('Invalid cafe data received');
        }

        if (foodcourtResponse && foodcourtResponse.data) {
          setFoodcourt(foodcourtResponse.data);
        } else {
          console.error('Invalid foodcourt data structure:', foodcourtResponse);
          setError('Invalid foodcourt data received');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [foodcourtId, navigate]);

  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-500">Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      {foodcourt && (
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800">{foodcourt.name}</h1>
          <p className="text-gray-600 mt-2">{foodcourt.description}</p>
        </div>
      )}
      <h2 className="text-2xl font-semibold mb-6 text-gray-700">Cafes in this Foodcourt</h2>
      {cafes.length === 0 ? (
        <p className="text-center text-gray-500">No cafes found in this foodcourt.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {cafes.map((cafe) => (
            <Link to={`/cafe/${cafe._id}`} key={cafe._id}>
              <CafeCard cafe={cafe} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default FoodcourtCafeList;