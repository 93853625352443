import React, { useState, useEffect, useCallback } from "react";
import { registerUserforoffer } from "../../api/user";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useNavigate } from "react-router-dom";
import { useTrackedNavigation } from "../../components/analytics/useTrackedNavigation";
import UniversalInput from "../../components/Universalnput";

const Register = () => {
  const [name, setName] = useState(() => localStorage.getItem("OG_name") || "");
  const [phone, setPhone] = useState(() => localStorage.getItem("OG_phone") || "");
  const navigate = useTrackedNavigation();

  const isIOS = useCallback(() => {
    const browserInfo = navigator.userAgent.toLowerCase();
    const platform = navigator?.userAgentData?.platform || navigator?.platform;

    return ["iphone", "ipad"].some((device) => browserInfo.includes(device)) ||
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(platform);
  }, []);

  useEffect(() => {
    const name = localStorage.getItem("OG_name");
    const phone = localStorage.getItem("OG_phone");
    if (name && phone) {
      registerUserforoffer({ name, phone })
        .then(() => navigate("/cafe/Offers"))
        .catch(() => navigate("/cafe/closed"));
    }
  }, [navigate]);

  const requestNotificationPermission = useCallback(async () => {
    if (isIOS()) return; // iOS doesn't support web push notifications

    if ("Notification" in window) {
      const permission = await Notification.requestPermission();
      if (permission === "granted" && "serviceWorker" in navigator) {
        try {
          const registration = await navigator.serviceWorker.register("../service-worker.js", { scope: "/" });
          console.log("Service Worker Registered", registration);

          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
          });

          await fetch(`${process.env.REACT_APP_BASE_URL}/api/user/subscribe`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ subscription, phone }),
          });

          console.log("Push Notification Subscription: ", subscription);
        } catch (err) {
          console.error("Service Worker registration failed: ", err);
        }
      }
    }
  }, [isIOS, phone]);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (phone.length !== 10 || isNaN(phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }
    localStorage.setItem("OG_name", name);
    localStorage.setItem("OG_phone", phone);
    try {
      await registerUserforoffer({ name, phone });
      await requestNotificationPermission();
      navigate("/cafe/Offers");
    } catch (error) {
      navigate("/cafe/closed");
    }
  }, [name, phone, navigate, requestNotificationPermission]);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <img src="../Cafe-admin.png" alt="Hero" />
      <Toaster />
      <h1 className="text-xl mb-1">Register yourself</h1>
      <h2 className="text-sm">Welcome to Order G</h2>
      <form onSubmit={onSubmit} className="my-8 w-full flex flex-col items-center">
        <UniversalInput
          type="text"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
          className="bg-gray-100 rounded-full w-80 p-4 my-1"
          placeholder="Your Name"
        />
        <div className="bg-gray-100 rounded-full w-80 p-4 my-1 flex">
          <p className="border-r-2 w-fit pr-2 border-gray-400">+91</p>
          <UniversalInput
            type="number"
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
            className="bg-gray-100 pl-2 outline-none focus:outline-none"
            placeholder="Your Phone"
          />
        </div>
        <button className="bg-[#FD7347] text-white rounded-full w-80 p-4 mt-4">
          Continue
        </button>
      </form>
    </div>
  );
};

export default React.memo(Register);