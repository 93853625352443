import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuReorderingComponent from '../../components/superadmin/MenuReorderingComponent';
import {
  createFoodcourt,
  associateCafeWithFoodcourt,
  getFoodcourts
} from '../../api/superadmin';
import UserAnalyticsDashboard from '../../components/superadmin/UserAnalyticsDashboard';

const API_BASE_URL = process.env.REACT_APP_BASE_URL

const SuperadminDashboard = () => {
  const [data, setData] = useState({
    admins: [],
    users: [],
    cafes: [],
    orders: [],
    questions: []
  });
  const [activeTab, setActiveTab] = useState('admins');
  const [loading, setLoading] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [file, setFile] = useState(null);

  const [alertTitle, setAlertTitle] = useState('');
  const [alertBody, setAlertBody] = useState('');
  const [alertUrl, setAlertUrl] = useState('');
  const [isSendingAlert, setIsSendingAlert] = useState(false);

  const [cafes, setCafes] = useState([]);
  const [selectedCafe, setSelectedCafe] = useState('');
  const [itemFile, setItemFile] = useState(null);

  const [selectedCafeForPassword, setSelectedCafeForPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [foodcourts, setFoodcourts] = useState([]);
  const [newFoodcourt, setNewFoodcourt] = useState({ name: '', address: '', city: '', state: '', pincode: '', description: '' });
  const [selectedFoodcourt, setSelectedFoodcourt] = useState('');
  const [selectedCafeForAssociation, setSelectedCafeForAssociation] = useState('');

  const sendAlert = async () => {
    if (!alertTitle || !alertBody) {
      toast.error('Title and body are required for the alert');
      return;
    }

    setIsSendingAlert(true);
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${API_BASE_URL}/api/superadmin/send-alert`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ title: alertTitle, body: alertBody, url: alertUrl })
      });

      const result = await response.json();
      if (response.ok) {
        toast.success(`Alert sent successfully. Successes: ${result.successes}, Failures: ${result.failures}`);
        setAlertTitle('');
        setAlertBody('');
        setAlertUrl('');
      } else {
        toast.error(`Failed to send alert: ${result.error}`);
      }
    } catch (error) {
      console.error('Error sending alert:', error);
      toast.error('An error occurred while sending the alert: ' + error.message);
    } finally {
      setIsSendingAlert(false);
    }
  };


  const renderAlertForm = () => (
    <div className="mb-4">
      <h3 className="text-xl font-bold mb-2">Send Alert</h3>
      <input
        type="text"
        value={alertTitle}
        onChange={(e) => setAlertTitle(e.target.value)}
        placeholder="Alert Title"
        className="mb-2 px-4 py-2 border rounded w-full"
      />
      <textarea
        value={alertBody}
        onChange={(e) => setAlertBody(e.target.value)}
        placeholder="Alert Body"
        className="mb-2 px-4 py-2 border rounded w-full"
      />
      <input
        type="url"
        value={alertUrl}
        onChange={(e) => setAlertUrl(e.target.value)}
        placeholder="Alert URL (optional)"
        className="mb-2 px-4 py-2 border rounded w-full"
      />
      <button
        onClick={sendAlert}
        disabled={isSendingAlert}
        className={`px-4 py-2 bg-blue-500 text-white rounded ${isSendingAlert ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isSendingAlert ? 'Sending...' : 'Send Alert'}
      </button>
    </div>
  );



  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('superadminToken');
    if (!token) {
      navigate('/superadmin/login');
    } else {
      fetchData(activeTab);
      fetchCafes();
      if (activeTab === 'foodcourts') {
        fetchFoodcourts();
      }
    }
  }, [activeTab, navigate]);

  const fetchCafes = async () => {
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${API_BASE_URL}/api/superadmin/cafes`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();
      if (response.ok) {
        setCafes(result.data);
        setData(prevData => ({ ...prevData, cafes: result.data }));
      } else {
        toast.error('Failed to fetch cafes');
      }
    } catch (error) {
      console.error('Error fetching cafes:', error);
      toast.error('An error occurred while fetching cafes');
    }
  };

  const fetchData = async (tab) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('superadminToken');
      // Use 'review-questions' instead of 'questions' for consistency with backend
      const url = `${API_BASE_URL}/api/superadmin/${tab === 'questions' ? 'review-questions' : tab}`;
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const result = await response.json();
      if (response.ok) {
        setData(prevData => ({ ...prevData, [tab]: result.data }));
      } else {
        if (response.status === 401) {
          toast.error('Session expired. Please login again.');
          localStorage.removeItem('superadminToken');
          navigate('/superadmin/login');
        } else {
          toast.error(result.message || `Failed to fetch ${tab}`);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error(`An error occurred while fetching ${tab}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchFoodcourts = async () => {
    try {
      const result = await getFoodcourts();
      setFoodcourts(result.data);
    } catch (error) {
      toast.error('Failed to fetch foodcourts');
    }
  };

  const handleCreateFoodcourt = async () => {
    try {
      await createFoodcourt(newFoodcourt);
      toast.success('Foodcourt created successfully');
      setNewFoodcourt({ name: '', address: '', city: '', state: '', pincode: '', description: '' });
      fetchFoodcourts();
    } catch (error) {
      toast.error('Failed to create foodcourt');
    }
  };

  const handleAssociateCafe = async () => {
    try {
      await associateCafeWithFoodcourt(selectedFoodcourt, selectedCafeForAssociation);
      toast.success('Cafe associated with foodcourt successfully');
      setSelectedFoodcourt('');
      setSelectedCafeForAssociation('');
      fetchFoodcourts();
    } catch (error) {
      toast.error('Failed to associate cafe with foodcourt');
    }
  };
  const renderFoodcourtManagement = () => (
    <div>
      <h2 className="text-xl font-bold mb-4">Foodcourt Management</h2>
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Create New Foodcourt</h3>
        <input
          type="text"
          placeholder="Name"
          value={newFoodcourt.name}
          onChange={(e) => setNewFoodcourt({ ...newFoodcourt, name: e.target.value })}
          className="mb-2 px-4 py-2 border rounded w-full"
        />
        <input
          type="text"
          placeholder="Address"
          value={newFoodcourt.address}
          onChange={(e) => setNewFoodcourt({ ...newFoodcourt, address: e.target.value })}
          className="mb-2 px-4 py-2 border rounded w-full"
        />
        <input
          type="text"
          placeholder="City"
          value={newFoodcourt.city}
          onChange={(e) => setNewFoodcourt({ ...newFoodcourt, city: e.target.value })}
          className="mb-2 px-4 py-2 border rounded w-full"
        />
        <input
          type="text"
          placeholder="State"
          value={newFoodcourt.state}
          onChange={(e) => setNewFoodcourt({ ...newFoodcourt, state: e.target.value })}
          className="mb-2 px-4 py-2 border rounded w-full"
        />
        <input
          type="text"
          placeholder="Pincode"
          value={newFoodcourt.pincode}
          onChange={(e) => setNewFoodcourt({ ...newFoodcourt, pincode: e.target.value })}
          className="mb-2 px-4 py-2 border rounded w-full"
        />
        <textarea
          placeholder="Description"
          value={newFoodcourt.description}
          onChange={(e) => setNewFoodcourt({ ...newFoodcourt, description: e.target.value })}
          className="mb-2 px-4 py-2 border rounded w-full"
        />
        <button
          onClick={handleCreateFoodcourt}
          className="px-4 py-2 bg-green-500 text-white rounded"
        >
          Create Foodcourt
        </button>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Associate Cafe with Foodcourt</h3>
        <select
          value={selectedFoodcourt}
          onChange={(e) => setSelectedFoodcourt(e.target.value)}
          className="mb-2 px-4 py-2 border rounded w-full"
        >
          <option value="">Select a foodcourt</option>
          {foodcourts.map(foodcourt => (
            <option key={foodcourt._id} value={foodcourt._id}>{foodcourt.name}</option>
          ))}
        </select>
        <select
          value={selectedCafeForAssociation}
          onChange={(e) => setSelectedCafeForAssociation(e.target.value)}
          className="mb-2 px-4 py-2 border rounded w-full"
        >
          <option value="">Select a cafe</option>
          {cafes.map(cafe => (
            <option key={cafe._id} value={cafe._id}>{cafe.name}</option>
          ))}
        </select>
        <button
          onClick={handleAssociateCafe}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Associate Cafe
        </button>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">Foodcourts</h3>
        {foodcourts.map(foodcourt => (
          <div key={foodcourt._id} className="mb-2 p-2 border rounded">
            <h4 className="font-semibold">{foodcourt.name}</h4>
            <p>{foodcourt.address}, {foodcourt.city}, {foodcourt.state} - {foodcourt.pincode}</p>
            <p>Associated Cafes: {foodcourt.cafe_names ? foodcourt.cafe_names.join(', ') : 'None'}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const addQuestion = async () => {
    if (!newQuestion.trim()) {
      toast.error('Question cannot be empty');
      return;
    }
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${API_BASE_URL}/api/superadmin/review-questions`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ question: newQuestion })
      });
      if (response.ok) {
        toast.success('Question added successfully');
        setNewQuestion('');
        fetchData('questions');
      } else {
        toast.error('Failed to add question');
      }
    } catch (error) {
      console.error('Error adding question:', error);
      toast.error('An error occurred while adding the question');
    }
  };

  const deleteQuestion = async (questionId) => {
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${API_BASE_URL}/api/superadmin/review-questions/${questionId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        toast.success('Question deleted successfully');
        fetchData('questions');
      } else {
        toast.error('Failed to delete question');
      }
    } catch (error) {
      console.error('Error deleting question:', error);
      toast.error('An error occurred while deleting the question');
    }
  };

  const toggleQuestionStatus = async (questionId) => {
    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${API_BASE_URL}/api/superadmin/review-questions/${questionId}/toggle-status`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        toast.success('Question status updated successfully');
        fetchData('questions');
      } else {
        toast.error('Failed to update question status');
      }
    } catch (error) {
      console.error('Error toggling question status:', error);
      toast.error('An error occurred while updating the question status');
    }
  };


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast.error('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${API_BASE_URL}/api/superadmin/create-cafes-from-csv`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        toast.success('Cafes created successfully');
        setFile(null);
        fetchData('cafes');
      } else {
        toast.error(result.message || 'Failed to create cafes');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('An error occurred while uploading the file');
    }
  };

  const handleDeleteCafe = async (cafeId) => {
    if (window.confirm('Are you sure you want to delete this cafe? This action cannot be undone.')) {
      try {
        const token = localStorage.getItem('superadminToken');
        const response = await fetch(`${API_BASE_URL}/api/superadmin/cafe/${cafeId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          toast.success('Cafe deleted successfully');
          fetchData('cafes'); // Refresh the cafe list
        } else {
          toast.error(result.message || 'Failed to delete cafe');
        }
      } catch (error) {
        console.error('Error deleting cafe:', error);
        toast.error('An error occurred while deleting the cafe');
      }
    }
  };

  const renderTable = (items) => {
    if (items.length === 0) return <p className="text-center py-4">No data available</p>;

    const headers = Object.keys(items[0]).filter(header => header !== 'permissions' && header !== 'image');

    return (
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              {headers.map(header => (
                <th key={header} className="px-6 py-3">{header}</th>
              ))}
              {activeTab === 'cafes' && (
                <div className="mb-4">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".csv"
                    className="mr-2"
                  />
                  <button
                    onClick={handleFileUpload}
                    className="px-4 py-2 bg-green-500 text-white rounded"
                  >
                    Upload CSV
                  </button>
                </div>
              )}
              {activeTab === 'cafes' && <th className="px-6 py-3">Permissions</th>}
              {activeTab === 'questions' && <th className="px-6 py-3">Actions</th>}
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index} className="bg-white border-b hover:bg-gray-50">
                {headers.map(header => (
                  <td key={`${index}-${header}`} className="px-6 py-4">
                    {typeof item[header] === 'object' ? JSON.stringify(item[header]) : item[header]}
                  </td>
                ))}
                {activeTab === 'cafes' && (
                  <td className="px-6 py-4">{renderPermissions(item)}</td>
                )}
                <td className="px-6 py-4">
                  <button
                    onClick={() => handleDeleteCafe(item._id)}
                    className="px-2 py-1 rounded bg-red-500 text-white"
                  >
                    Delete
                  </button>
                </td>

                {activeTab === 'questions' && (
                  <td className="px-6 py-4">
                    <button
                      onClick={() => toggleQuestionStatus(item._id)}
                      className={`px-2 py-1 rounded mr-2 ${item.is_active ? 'bg-green-500' : 'bg-red-500'} text-white`}
                    >
                      {item.is_active ? 'Active' : 'Inactive'}
                    </button>
                    <button
                      onClick={() => deleteQuestion(item._id)}
                      className="px-2 py-1 rounded bg-red-500 text-white"
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };


  const handleItemFileChange = (e) => {
    setItemFile(e.target.files[0]);
  };

  const handleBulkItemUpload = async () => {
    if (!selectedCafe) {
      toast.error('Please select a cafe');
      return;
    }
    if (!itemFile) {
      toast.error('Please select a CSV file');
      return;
    }

    const formData = new FormData();
    formData.append('file', itemFile);

    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${API_BASE_URL}/api/superadmin/bulk-item-upload/${selectedCafe}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        toast.success('Items uploaded successfully');
        setItemFile(null);
        setSelectedCafe('');
      } else {
        toast.error(result.message || 'Failed to upload items');
      }
    } catch (error) {
      console.error('Error uploading items:', error);
      toast.error('An error occurred while uploading items');
    }
  };

  const renderBulkItemUploadForm = () => (
    <div className="mb-4">
      <h3 className="text-xl font-bold mb-2">Bulk Item Upload</h3>
      <select
        value={selectedCafe}
        onChange={(e) => setSelectedCafe(e.target.value)}
        className="mb-2 px-4 py-2 border rounded w-full"
      >
        <option value="">Select a cafe</option>
        {cafes.map(cafe => (
          <option key={cafe._id} value={cafe._id}>{cafe.name}</option>
        ))}
      </select>
      <input
        type="file"
        onChange={handleItemFileChange}
        accept=".csv"
        className="mb-2"
      />
      <button
        onClick={handleBulkItemUpload}
        className="px-4 py-2 bg-green-500 text-white rounded"
      >
        Upload Items
      </button>
    </div>
  );
  const handleChangePassword = async () => {
    if (!selectedCafeForPassword || !newPassword) {
      toast.error('Please select a cafe and enter a new password');
      return;
    }

    try {
      const token = localStorage.getItem('superadminToken');
      const response = await fetch(`${API_BASE_URL}/api/superadmin/change-cafe-admin-password`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ cafeId: selectedCafeForPassword, newPassword })
      });

      const result = await response.json();
      if (response.ok) {
        toast.success('Admin password updated successfully');
        setSelectedCafeForPassword('');
        setNewPassword('');
      } else {
        toast.error(result.message || 'Failed to update admin password');
      }
    } catch (error) {
      console.error('Error changing admin password:', error);
      toast.error('An error occurred while changing the admin password');
    }
  };

  const renderPasswordChangeForm = () => (
    <div className="mb-4">
      <h3 className="text-xl font-bold mb-2">Change Cafe Admin Password</h3>
      <select
        value={selectedCafeForPassword}
        onChange={(e) => setSelectedCafeForPassword(e.target.value)}
        className="mb-2 px-4 py-2 border rounded w-full"
      >
        <option value="">Select a cafe</option>
        {cafes.map(cafe => (
          <option key={cafe._id} value={cafe._id}>{cafe.name}</option>
        ))}
      </select>
      <input
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        placeholder="Enter new password"
        className="mb-2 px-4 py-2 border rounded w-full"
      />
      <button
        onClick={handleChangePassword}
        className="px-4 py-2 bg-blue-500 text-white rounded"
      >
        Change Password
      </button>
    </div>
  );

  const renderContent = () => {
    if (loading) {
      return <p className="text-center py-4">Loading...</p>;
    }

    switch (activeTab) {
      case 'foodcourts':
        return renderFoodcourtManagement();
      case 'alerts':
        return renderAlertForm();
      case 'bulkItemUpload':
        return renderBulkItemUploadForm();
      case 'menuReordering':
        return <MenuReorderingComponent />;
      case 'questions':
        return (
          <>
            <div className="mb-4">
              <input
                type="text"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                placeholder="Enter new question"
                className="mr-2 px-4 py-2 border rounded"
              />
              <button
                onClick={addQuestion}
                className="px-4 py-2 bg-green-500 text-white rounded"
              >
                Add Question
              </button>
            </div>
            {renderTable(data[activeTab])}
          </>
        );
      case 'cafes':
        return (
          <>
            <div className="mb-4">
              <input
                type="file"
                onChange={handleFileChange}
                accept=".csv"
                className="mr-2"
              />
              <button
                onClick={handleFileUpload}
                className="px-4 py-2 bg-green-500 text-white rounded"
              >
                Upload CSV
              </button>
            </div>
            {renderTable(data[activeTab])}
          </>
        );
      case 'changePassword':
        return renderPasswordChangeForm();
      case 'userAnalytics':
        return <UserAnalyticsDashboard />;
      default:
        return renderTable(data[activeTab]);
    }
  };



  const updateCafePermissions = async (cafeId, updatedPermissions) => {
    try {
      const token = localStorage.getItem('superadminToken');
      const url = `${API_BASE_URL}/api/superadmin/cafe-permissions/${cafeId}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ permissions: updatedPermissions })
      });

      if (response.ok) {
        toast.success('Permissions updated successfully');
        fetchData('cafes');
      } else {
        toast.error('Failed to update permissions');
      }
    } catch (error) {
      console.error('Error updating permissions:', error);
      toast.error('An error occurred while updating permissions');
    }
  };

  const renderPermissions = (cafe) => {
    if (!cafe.permissions) return 'No permissions set';
    return (
      <div>
        {Object.entries(cafe.permissions).map(([key, value]) => (
          <div key={key} className="flex items-center mb-2">
            <span className="mr-2">{key}:</span>
            <button
              onClick={() => updateCafePermissions(cafe._id, {
                ...cafe.permissions,
                [key]: !value
              })}
              className={`px-2 py-1 rounded ${value ? 'bg-green-500' : 'bg-red-500'} text-white`}
            >
              {value ? 'Enabled' : 'Disabled'}
            </button>
          </div>
        ))}
      </div>
    );
  };


  const handleLogout = () => {
    localStorage.removeItem('superadminToken');
    navigate('/superadmin/login');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Superadmin Dashboard</h1>
        <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded">Logout</button>
      </div>
      <div className="mb-4">
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'admins' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('admins')}
        >
          Admins
        </button>
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'users' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('users')}
        >
          Users
        </button>
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'cafes' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('cafes')}
        >
          Cafes
        </button>
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'orders' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('orders')}
        >
          Orders
        </button>
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'questions' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('questions')}
        >
          Questions
        </button>
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'alerts' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('alerts')}
        >
          Alerts
        </button>
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'bulkItemUpload' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('bulkItemUpload')}
        >
          Bulk Item Upload
        </button>
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'changePassword' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('changePassword')}
        >
          Change Admin Password
        </button>

        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'menuReordering' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('menuReordering')}
        >
          Menu Reordering
        </button>
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'foodcourts' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('foodcourts')}
        >
          Foodcourts
        </button>
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'userAnalytics' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('userAnalytics')}
        >
          User Analytics
        </button>

      </div>

      <div>
        {renderContent()}
      </div>
      <ToastContainer />
    </div>
  );
};

export default SuperadminDashboard;