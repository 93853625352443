import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReviewItem from "../../components/reviewItem";
import { fetchOrderToRate, addOrderRating, addOrderReview } from "../../api/user";
import LoadingSpinner from "./LoadingSpinner";
import PropTypes from 'prop-types';

const RatingButton = ({ value, rating, onClick }) => (
  <button
    onClick={() => onClick(value)}
    className={`border text-xs py-2 px-4 m-1 rounded-full ${
      rating === value ? "border-2 border-black" : ""
    } bg-${value === 1 ? "red-500" : value === 2 ? "orange-400" : value === 3 ? "yellow-200" : value === 4 ? "green-300" : "green-400"}`}
  >
    {value}
  </button>
);

RatingButton.propTypes = {
  value: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Review = () => {
  const { order } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [reviewAdded, setReviewAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [animate, setAnimation] = useState("");
  const [display, setDisplay] = useState("block");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const res = await fetchOrderToRate(order);
        setOrderDetails(res.data);
      } catch (err) {
        navigate("/404");
        console.error(err);
      }
    };

    fetchOrderDetails();
  }, [order, navigate]);

  const handleReview = useCallback(async () => {
    setLoading(true);
    try {
      const res = await addOrderReview(order, review);
      if (res.success) {
        setReviewAdded(true);
        setAnimation("transition duration-1000 opacity-0 scale-0");
        setTimeout(() => {
          setDisplay("hidden");
        }, 500);
        if (rating) navigate("/404");
      }
    } catch (error) {
      console.error("Error adding review:", error);
    } finally {
      setLoading(false);
    }
  }, [order, review, rating, navigate]);

  const handleOrderRating = useCallback(async (val) => {
    setRating(val);
    try {
      await addOrderRating(order, val);
    } catch (error) {
      console.error("Error adding rating:", error);
    }
  }, [order]);

  if (!orderDetails) return <LoadingSpinner />;

  if (orderDetails.rating)
    return (
      <div className="flex flex-col h-screen w-full items-center justify-center">
        Already reviewed
      </div>
    );

  return (
    <div className="flex flex-col h-screen w-full items-center">
      <Helmet
        title="Review"
        meta={[
          {
            name: "description",
            content: "Review your order",
          },
          {
            property: "og:title",
            content: "Review",
          },
        ]}
      />
      <div className="w-full border-b flex justify-center items-center">
        <img src="../icon.svg" alt="" className="h-6 mr-2" />
        <h1 className="text-xl font-semibold py-4">Rating</h1>
      </div>
      <div className="flex flex-col w-full my-8">
        <p className="text-center text-sm mb-2">
          Rate your overall experience!
        </p>
        <div className="flex justify-center">
          {[1, 2, 3, 4, 5].map((value) => (
            <RatingButton key={value} value={value} rating={rating} onClick={handleOrderRating} />
          ))}
        </div>
      </div>
      {!reviewAdded && (
        <div className="flex flex-col w-full items-center">
          {orderDetails.itemsToReview.map((item) => (
            <ReviewItem key={item.id} item={item} />
          ))}
          <div
            className={`flex border border-gray-300 shadow rounded my-4 flex-col justify-between w-11/12 ${animate} ${display}`}
          >
            <div className="w-full justify-center items-center py-4 border-b">
              <p className="text-center text-sm">
                Please rate your overall experience
              </p>
            </div>
            <div className="flex flex-col p-4">
              <h2 className="text-sm">Add detailed review</h2>
              <textarea
                name="review"
                id="review"
                cols="30"
                rows="5"
                className="bg-gray-100 border border-gray-300 rounded-3xl mt-2 text-sm p-4 w-full focus:outline-none"
                placeholder="Write your review here..."
                value={review}
                onChange={(e) => setReview(e.target.value)}
              ></textarea>
            </div>
            <button
              onClick={handleReview}
              disabled={loading}
              className="bg-[#FD7347] shadow-2xl disabled:bg-[#FD7347] text-white py-2 mx-8 mb-4 rounded-xl"
            >
              Submit Review
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Review);